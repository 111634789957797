<template>
  <!-- prettier-ignore -->
  <index-content>
    <template v-if="hasRW" #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="id">{{ $t('label.id') }}</th>
            <th class="type">{{ $t('label.type') }}</th>
            <th class="col-weight-3">{{ $t('label.name') }}</th>
            <th class="col-weight-5">{{ $t('label.comment') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-enums:AdminPrivilegeType="row.type"></td>
            <td>{{ row.name | hyphen }}</td>
            <td class="n-ellipsis" :title="row.comment">{{ row.comment | hyphen }}</td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import OperatorView from './view';
import ModalPrivilege from './ModalPrivilege';
import { fetch } from '@/api/operator/operator-privilege';

export default OperatorView.extend({
  name: 'OperatorPrivilege',
  data() {
    return {
      preload: true,
    };
  },
  methods: {
    doLoad() {
      return fetch();
    },
    parse(rows) {
      this.records = rows;
    },
    openModal(model = {}) {
      this.createModal(ModalPrivilege, { model, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 850px;

  th.id,
  th.type {
    width: 5rem;
  }
}
</style>
