import { $fetch, $post, $put } from '@/services/axios';

export function fetch() {
  return $fetch(`operator/privileges`);
}

export function validate(model) {
  return $post(`operator/privilege/validate`, model);
}

export function create(model) {
  return $put(`operator/privilege`, model);
}

export function update(model) {
  return $post(`operator/privilege/${model.id}`, model);
}
