<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :width="500">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col1>
        <n-field-enum v-model="model.type" field-name="type" enum-name="AdminPrivilegeType" label="type" :error="error.type" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.comment" field-name="comment" label="comment" :error="error.comment" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, create, update } from '@/api/operator/operator-privilege';

export default NModal.extend({
  name: 'ModalPrivilege',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
