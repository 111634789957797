import BaseView from '@/views/index/base-view';
import { mapState } from 'vuex';
import { AdminNoticeType } from '@/constant/enums';

export default BaseView.extend({
  computed: {
    ...mapState('profile', ['companyId']),
    noticeTypes() {
      let r = [];
      Object.keys(this.$enums.AdminNoticeType).forEach(t => {
        let type = AdminNoticeType[t];
        if (this.condition.domain === 0 || this.condition.domain === type.domain) {
          r.push({ name: t, value: type.value });
        }
      });
      return r;
    },
  },
  beforeMount() {
    this._companyId = this.isSuper ? 0 : this.companyId;
  },
});
